// src/utils/chartUtils.js
export function getMetricChartOptions(metricQuery, isPreview = false, options = {}) {
  // Define a set of colors to use for different series
  const colors = [
    {
      line: 'rgba(238, 199, 41, 1)', // Yellow
      fillStart: 'rgba(238, 199, 41, 0.20)',
      fillEnd: 'rgba(238, 199, 41, 0.00)',
    },
    {
      line: 'rgba(237, 125, 49, 1)', // Orange
      fillStart: 'rgba(237, 125, 49, 0.20)',
      fillEnd: 'rgba(237, 125, 49, 0.00)',
    },
    {
      line: 'rgba(91, 155, 213, 1)', // Blue
      fillStart: 'rgba(91, 155, 213, 0.20)',
      fillEnd: 'rgba(91, 155, 213, 0.00)',
    },
    {
      line: 'rgba(112, 173, 71, 1)', // Green
      fillStart: 'rgba(112, 173, 71, 0.20)',
      fillEnd: 'rgba(112, 173, 71, 0.00)',
    },
    {
      line: 'rgba(165, 165, 165, 1)', // Gray
      fillStart: 'rgba(165, 165, 165, 0.20)',
      fillEnd: 'rgba(165, 165, 165, 0.00)',
    },
    // Add more colors if needed
  ];
  const rawTitle = metricQuery.query_title || metricQuery.query || 'Metric';
  const title = !options.isExpanded && rawTitle.length > 100
    ? rawTitle.substring(0, 97) + '...' 
    : rawTitle;

  // Common options
  const chartOptions = {
    chart: {
      type: options.chartType || 'area',
      backgroundColor: null, // Set to null for transparency
      borderRadius: 12,
      spacing: isPreview ? [5, 5, 5, 5] : [10, 10, 15, 10],
      width: isPreview ? null : null, // Width will be controlled by the container
      height: isPreview ? 145 : null, // Adjust height as needed
      events: {
        render() {
          const chart = this;
          if (options.setSyncLineValue) {
            // Remove any existing mousemove listeners
            const oldListeners = chart.container.listeners?.('mousemove') || [];
            oldListeners.forEach(listener => {
              chart.container.removeEventListener('mousemove', listener);
            });
            chart.container.addEventListener('mousemove', function (event) {
              const point = chart.pointer.normalize(event); // Normalize the event position for Highcharts
              const xAxisValue = chart.xAxis[0].toValue(point.chartX);
              options.setSyncLineValue(xAxisValue);
            });
          }
        },
      }
    },
    title: {
      text: title,
      style: {
        color: 'var(--colors-neutral-gray-600, #DBDCDA)',
        fontSize: isPreview ? '10px' : '14px',
      },
    },
    xAxis: {
      type: 'datetime',
      lineColor:
        'var(--colors-neutral-gray-50-alpha, rgba(255, 255, 255, 0.08))',
      tickColor:
        'var(--colors-neutral-gray-50-alpha, rgba(255, 255, 255, 0.08))',
      labels: {
        style: {
          color: 'var(--colors-neutral-gray-600, #DBDCDA)',
          fontSize: isPreview ? '8px' : '12px',
        },
      },
      title: {
        text: '',
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      gridLineColor:
        'var(--colors-neutral-gray-50-alpha, rgba(255, 255, 255, 0.08))',
      labels: {
        style: {
          color: 'var(--colors-neutral-gray-600, #DBDCDA)',
          fontSize: isPreview ? '8px' : '12px',
        },
      },
    },
    series: [],
    plotOptions: {
      area: {
        marker: {
          enabled: false,
        },
      },
      series: {
        shadow: false,
        events: {
          legendItemClick: function (e) {
            const chart = this.chart;
            const visibleSeries = chart.series.filter(s => s.visible); // Find all visible series

            console.log("Event in legend is ", e);

            // Check if the Shift key is pressed
            if (e.browserEvent.shiftKey) {
              // If the clicked series is the only visible series, show all series
              // console.log("visibleSeries: ", visibleSeries);
              if (visibleSeries.length === 0) {
                chart.series.forEach(function (series) {
                  series.setVisible(true, false); // Show all series
                });
                chart.redraw();
              } else {
                // Hide all other series except the clicked one
                chart.series.forEach(function (series) {
                  if (series !== e.target) {
                    series.setVisible(false, false);
                  } else {
                    series.setVisible(true, false);
                  }
                });
                chart.redraw();
              }
              return false; // Prevent default behavior (disabling series)
            }

            // If Shift is not pressed, use the default behavior
            return true; // Allow default action (toggling the visibility)
          },
          click: function (e) {
            const chart = this.chart;
            const visibleSeries = chart.series.filter((s) => s.visible);

            if (e.shiftKey) {
              return;
            }

            // If no series are visible, show all series
            if (visibleSeries.length === 1) {
              chart.series.forEach(function (series) {
                series.setVisible(true, false);
              });
              chart.redraw();
            } else {
              // Hide all other series except the clicked one
              chart.series.forEach((series) => {
                if (series !== this) {
                  series.setVisible(false, false);
                } else {
                  series.setVisible(true, false);
                }
              });
              chart.redraw();
            }
          },
        },
      },
    },
    tooltip: {
      enabled: !isPreview, // Disable tooltips in preview
      pointFormat: '{point.y}',
      shared: false, // disable shared to show individual point tooltips
      split: false, // disable split to show individual point tooltips  
    },
    legend: {
      enabled: true, // Enable legend since we have multiple series
      itemStyle: {
        color: 'var(--colors-neutral-gray-600, #DBDCDA)',
        fontSize: isPreview ? '8px' : '12px',
      },
      itemHoverStyle: {
        color: 'var(--colors-primary-green, #40853E)', // Set hover color to primary green
      },      
      maxHeight: isPreview ? 45 : null, // Limit height when in preview
      navigation: {
        activeColor: '#FFFFFF', // Set pagination arrow active color to white
        inactiveColor: '#FFFFFF', // Set pagination arrow inactive color to white
        style: {
          color: '#FFFFFF', // Set pagination text color to white
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  if (options.onChartSelection) {
    const existingEvents = chartOptions.chart.events || {};
    chartOptions.chart.events = { ...existingEvents, selection: options.onChartSelection };
    chartOptions.chart.zoomType = "x";
  }

  if (options.startTime) {
    chartOptions.xAxis.min = new Date(options.startTime).getTime();
  }

  if (options.endTime) {
    chartOptions.xAxis.max = new Date(options.endTime).getTime();
  }

  if (options.timeOfInterest) {
    const existingPlotlines = chartOptions.xAxis.plotLines || []
    chartOptions.xAxis.plotLines = [...existingPlotlines, {
      color: 'red',
      dashStyle: 'dash',
      value: new Date(options.timeOfInterest).getTime(),
      width: 1,
      label: {
        text: 'Incident Time',
        style: {
          color: 'var(--colors-neutral-gray-600, #DBDCDA)'
        }
      }
    }];
  }

  // Loop over each metric and add it to the series
  console.log('metricQuery', metricQuery)
  metricQuery.metrics.forEach((metric, index) => {
    console.log('metric', metric)
    console.log('metric', metric.values)
    if (metric.values === undefined) {
      return;
    }
    const data = Object.entries(metric.values).map(
      ([datetimeStr, value]) => {
        const timestamp = new Date(datetimeStr).getTime();
        return [timestamp, value];
      }
    );

    data.sort((a, b) => a[0] - b[0]);

    const colorIndex = index % colors.length;
    const color = colors[colorIndex];

    let series_name = metric.name || `Series ${index + 1}`;
    if (metric.metadata) {
      const key = Object.keys(metric.metadata)[0];
      if (key) {
        series_name = metric.metadata[key];
      }
    }

    chartOptions.series.push({
      name: series_name,
      data,
      color: color.line,
      lineWidth: isPreview ? 1 : 2,
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, color.fillStart],
          [1, color.fillEnd],
        ],
      },
    });

  });

  return chartOptions;
}
