<template>
  <div
    :class="[
      'input-form',
      { 'is-disabled': disabled }
    ]"
  >
    <v-form ref="form" @submit.prevent="onSubmit">
      <!-- First row: Incident Time and Dropdown -->
      <div class="input-row">
        <div class="input-field incident-time">
          <label class="input-label">Incident Time</label>
          <v-text-field
            v-model="internalIncidentTime"
            :placeholder="incidentTimePlaceholder"
            :disabled="disabled"
            @focus="onFocus"
            @blur="onBlur"
            @keydown="onKeydown"
            @input="onInputIncidentTime"
            dense
            hide-details
            outlined
            class="incident-time-input"
            variant="outlined"
          ></v-text-field>
        </div>
        <div class="input-field agent-type" style="display: none;">
          <label class="input-label">Search Space</label>
          <v-select
            v-model="internalAgentTypeValue"
            :items="agentTypeOptions"
            item-title="label"
            item-value="value"
            :disabled="disabled"
            @change="onAgentTypeChange"
            dense
            hide-details
            outlined
            class="agent-type-select"
            variant="outlined"
          ></v-select>
        </div>
      </div>
      <!-- Second row: Summary -->
      <div class="input-row">
        <div class="input-field summary">
          <label class="input-label">Summary</label>
          <v-textarea
            v-model="internalSummary"
            :placeholder="summaryPlaceholder"
            :disabled="disabled"
            @focus="onFocus"
            @blur="onBlur"
            @keydown="onKeydown"
            @input="onInputSummary"
            auto-grow
            hide-details
            outlined
            class="summary-textarea"
            rows="6"
            rounded="8"
            variant="outlined"
          ></v-textarea>
        </div>
      </div>
    </v-form>
  </div>
    <!-- Submit button -->
    <div class="submit-button">
        <PrimaryButton
            type="primary"
            :disabled="submitDisabled"
            icon="arrow"
            iconPosition="right"
            @click="onSubmit"
        >
            Start Investigation
        </PrimaryButton>
    </div>
</template>

<script>
import PrimaryButton from './Buttons/PrimaryButton.vue';

export default {
  name: 'InputForm',
    components: {
        PrimaryButton,
    },
  props: {
    value: {
      type: String,
      default: '',
    },
    agentTypeValue: {
      type: String,
      default: 'log_agent',
    },
    agentTypeOptions: {
      type: Array,
      default: () => [
        { value: 'log_agent', label: 'Search Logs' },
        // Add other options here if needed
      ],
    },
    showAgentTypeDropdown: {
      type: Boolean,
      default: true,
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Enter text',
    },
    questions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      internalValue: this.value,
      internalAgentTypeValue: this.agentTypeValue,
      internalIncidentTime: '',
      internalSummary: '',
      incidentTimePlaceholder: 'Time and timezone of when the issue was first noticed (e.g., Nov. 18th 2024, 11am ET)',
      summaryPlaceholder: `Brief incident description from incident or escalation Slack channels.
Optionally, copy-paste any relevant
  - error messages
  - code snippets
  - names of affected services
`,
    };
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue;
    },
    agentTypeValue(newValue) {
      this.internalAgentTypeValue = newValue;
    },
    internalAgentTypeValue(newValue) {
      this.$emit('update:agentTypeValue', newValue);
    },
  },
  methods: {
    onInputIncidentTime() {
      this.emitInputField();
    },
    onInputSummary() {
      this.emitInputField();
    },
    emitInputField() {
      const combinedValue = `Start Time:\n${this.internalIncidentTime}\n\nSummary:\n${this.internalSummary}`;
      this.internalValue = combinedValue;
      const event = { target: { value: combinedValue } };
      this.$emit('inputField', event);
    },
    onAgentTypeChange() {
      // Emitted through watcher
    },
    onSubmit() {
      this.$emit('submit');
    },
    onFocus() {
      // Handle focus if needed
    },
    onBlur() {
      // Handle blur if needed
    },
    onKeydown(event) {
      if (event.key === 'Enter') {
        if (event.shiftKey || event.ctrlKey || event.metaKey) {
          // Allow new lines
        } else {
          event.preventDefault();
          this.onSubmit();
        }
      }
    },
  },
};
</script>

<style scoped>
.input-form {
  border-radius: 12px;
  background: var(--colors-neutral-gray-25, #161F15);
  width: 800px;
  flex-shrink: 0;
  padding: 16px;
}

.input-row {
  display: flex;
  align-items: center;
  padding: 4px 0;
  gap: 16px;
}

.input-field {
  display: flex;
  flex-direction: column;
}

.incident-time,
.agent-type {
  flex: 1;
}

.summary {
  width: 100%;
}

.input-label {
  color: var(--colors-neutral-gray-400, #929691);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 120% */
  margin-bottom: 4px;
}

.submit-button {
/*Uncomment to center  */
  display: flex;
  justify-content: center;
  align-items: center; 
  margin-top: 16px; /* Adjust if needed */
}

.is-disabled {
  opacity: 0.5;
}

/* Specific styles for inputs */
.incident-time-input,
.agent-type-select,
.summary-textarea {
  background: var(--colors-neutral-gray-25, #161F15);
}

.summary-textarea {
  min-height: 80px;
}

/* Fix for the overlay issue */
.v-text-field,
.v-textarea {
  margin: 0;
  padding: 0;
}

</style>
