<template>
  <div class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <h3>Share Session</h3>
      <p>Copy this link to share the session:</p>
      <p class="shared-link-input">{{ sharedLink }}</p>
      <div class="modal-buttons">
        <PrimaryButton type="standard" @click="closeModal">Close</PrimaryButton>
        <PrimaryButton type="primary" @click="unshareSession">Unshare</PrimaryButton>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from './Buttons/PrimaryButton.vue';

export default {
  name: 'ShareModal',
  components: {
    PrimaryButton,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    sharedLink: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    unshareSession() {
      this.$emit('unshare');
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--colors-black, #0C160B);
  padding: 20px;
  border-radius: 16px;
  width: 400px;
  max-width: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shared-link-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid var(--colors-neutral-gray-600, #DBDCDA);
  border-radius: 8px;
  background-color: var(--colors-neutral-gray-50, #1F291F);
  color: var(--colors-white, #FFF);
}

.modal-buttons {
  display: flex;
  gap: 16px;
}
</style>
