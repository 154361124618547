<template>
  <div class="session-menu" :class="{ visible: showMenu }" :style="menuStyle" @click.stop>
    <ul class="menu-list">
      <li @click="onDelete">Delete</li>
      <li @click="onRename">Rename</li>
      <li @click="onShare">Share</li>

      <!-- add future options here -->
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SessionMenu',
  props: {
    showMenu: {
      type: Boolean,
      default: false,
    },
    sessionId: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      default: 'down', // 'up' or 'down'
    },

  },
  computed: {
    menuStyle() {
      if (this.position === 'up') {
        return {
          bottom: '100%', // Position the menu above the button
          right: '0',
        };
      } else {
        return {
          top: '100%', // Position the menu below the button
          right: '0',
        };
      }
    },
  },
  methods: {
    onDelete() {
      this.$emit('open-modal', { action: 'delete', sessionId: this.sessionId });
      this.$emit('close-menu');
    },
    onRename() {
      this.$emit('open-modal', { action: 'rename', sessionId: this.sessionId });
      this.$emit('close-menu');
    },
    onShare() {
      this.$emit('open-modal', { action: 'share', sessionId: this.sessionId });
      this.$emit('close-menu');
    },

    // Add future methods here
  },
};
</script>

<style scoped>
/* Existing styles */
.session-menu {
  position: absolute;
  background-color: #1F291F;
  border-radius: 8px;
  padding: 8px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 50;
}

.session-menu.visible {
  visibility: visible;
  opacity: 1;
}

.menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-list li {
  padding: 8px 16px;
  color: #DBDCDA;
  cursor: pointer;
}

.menu-list li:hover {
  background-color: #353E34;
}
</style>
