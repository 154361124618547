<template>
  <div class="patch-container">
    <div class="file-header">
      <div class="filename" @click="isCollapsed = !isCollapsed">{{ filename }}</div>
    </div>
    <div v-if="!isCollapsed">
      <div class="patch-header">
        {{ parsedPatch.header }}
      </div>
      <div class="patch-content">
        <table>
          <tbody>
            <tr v-for="(line, index) in parsedPatch.lines" :key="index" :class="line.type">
              <td class="line-number old">{{ line.oldNumber }}</td>
              <td class="line-number new">{{ line.newNumber }}</td>
              <td class="line-content">
                <pre><code><span class="prefix">{{ line.content[0] }}</span>{{ line.content.slice(1) }}</code></pre>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GitPatch',
  props: {
    filename: String,
    patch: String
  },
  data() {
    return {
      isCollapsed: false
    }
  },
  computed: {
    parsedPatch() {
      console.log(this.patch);
      const lines = this.patch.split('\n');
      const headerMatch = lines[0].match(/@@ -(\d+),\d+ \+(\d+),\d+ @@/);
      const startLineOld = headerMatch ? parseInt(headerMatch[1]) : 1;
      const startLineNew = headerMatch ? parseInt(headerMatch[2]) : 1;
      const header = lines[0];
      const content = lines.slice(1);

      let oldLineNumber = startLineOld - 1;
      let newLineNumber = startLineNew - 1;

      return {
        header,
        lines: content.map(line => {
          const isAddition = line.startsWith('+');
          const isDeletion = line.startsWith('-');
          const isContext = !isAddition && !isDeletion && !line.startsWith('@');

          if (isDeletion || isContext) oldLineNumber++;
          if (isAddition || isContext) newLineNumber++;

          return {
            type: isAddition ? 'addition' :
              isDeletion ? 'deletion' :
                line.startsWith('@') ? 'info' : 'context',
            content: line,
            oldNumber: isDeletion || isContext ? oldLineNumber : null,
            newNumber: isAddition || isContext ? newLineNumber : null
          };
        })
      };
    }
  }
}
</script>

<style scoped>
.patch-container {
  font-family: ui-monospace, SFMono-Regular, 'SF Mono', Menlo, Consolas, 'Liberation Mono', monospace;
  border: 1px solid #30363d;
  border-radius: 6px;
  margin: 16px 0;
  background-color: #0d1117;
}

.file-header {
  padding: 8px 16px;
  background-color: #161b22;
  border-bottom: 1px solid #30363d;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  text-align: left;
  background-color: #21262d;
}

.filename {
  font-weight: 600;
  color: #c9d1d9;
  word-wrap: break-word;
  overflow-wrap: break-word;
  cursor: pointer;
}

.patch-header {
  padding: 8px 16px;
  color: #8b949e;
  background-color: #161b22;
  border-bottom: 1px solid #30363d;
  text-align: left;
  font-size: 12px;
}

.patch-content {
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.patch-content::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

table {
  border-spacing: 0;
  width: 100%;
  font-size: 12px;
  table-layout: fixed;
}

.line-number {
  padding: 0 8px;
  text-align: right;
  color: #8b949e;
  width: 50px;
  background-color: #161b22;
  user-select: none;
}

.line-number.old {
  border-right: 1px solid #30363d;
}

.line-number.new {
  border-right: 1px solid #30363d;
}

.line-content {
  padding: 0;
  white-space: pre;
  text-align: left;
}

.prefix {
  padding-left: 8px;
  padding-right: 4px;
  user-select: none;
}

pre {
  margin: 0;
}

code {
  font-family: inherit;
}

.addition {
  background-color: #1a4721;
}

.addition .line-number {
  background-color: #1b4721;
}

.deletion {
  background-color: #4a1c1c;
}

.deletion .line-number {
  background-color: #4a1c1c;
}

.info {
  color: #8b949e;
  background-color: #161b22;
}

tr:hover {
  background-color: #1c2026;
}

tr.addition:hover {
  background-color: #1e5524;
}

tr.deletion:hover {
  background-color: #5d2020;
}
</style>