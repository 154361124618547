<!-- ProfileAvatar.vue -->
<template>
  <div class="avatar-container">
    <div class="avatar-circle">
      <!-- Wrap the image in an anchor tag -->
      <!-- <a :href="imageSrc" target="_blank">
        <img :src="imageSrc" alt="ProfileAvatar" />
      </a> -->
      <img :src="imageSrc" alt="ProfileAvatar" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      default: require('../assets/logomark.svg') // Default image, can be replaced
    }
  }
};
</script>

<style scoped>
.avatar-container {
  display: flex;
  width: var(--spacing-832-px, 32px);
  height: var(--spacing-832-px, 32px);
  justify-content: center;
  align-items: center;
  background-color: transparent; /* Container is transparent */
}

.avatar-circle {
  width: calc(var(--spacing-832-px, 32px) - 2px);
  height: calc(var(--spacing-832-px, 32px) - 2px);
  border-radius: 50%;
  border: 1px solid var(--colors-neutral-gray-200, #495048);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensures the image stays within the circle */
}

/* Add cursor: pointer to indicate it's clickable */
.avatar-circle a {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/* Ensure the image fills the parent anchor */
.avatar-circle img {
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Ensures the image itself is circular */
}
</style>
