<template>
  <div class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <h3>{{ title }}</h3>
      <div v-if="type === 'rename'">
        <input v-model="inputValue" type="text" placeholder="Enter new session name" class="input-modal"/>
      </div>
      <p>{{ message }}</p>
      <div class="modal-buttons">
        <PrimaryButton type="standard" @click="closeModal">Cancel</PrimaryButton>
        <PrimaryButton type="primary" @click="confirmAction">Confirm</PrimaryButton>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from './Buttons/PrimaryButton.vue';

export default {
  name: 'ConfirmationModal',
  components: {
    PrimaryButton,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true, // 'delete' or 'rename'
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    sessionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inputValue: '',
    };
  },
  methods: {
    confirmAction() {
      if (this.type === 'rename' && !this.inputValue.trim()) {
        alert('Please enter a new session name.');
        return;
      }
      this.$emit('confirm', {
        sessionId: this.sessionId,
        newName: this.inputValue.trim(),
      });
      this.closeModal();
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  /* Your existing modal overlay styles */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  /* Your existing modal content styles */
  background-color: var(--colors-black, #0C160B);
  padding: 20px;
  border-radius: 16px;
  width: 400px;
  max-width: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content h3 {
  color: var(--colors-white, #FFF);
  margin-bottom: 20px;
}

.modal-content p {
  color: var(--colors-white, #FFF);
  margin-bottom: 20px;
  text-align: center;
}

.modal-content input {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid var(--colors-neutral-gray-600, #DBDCDA);
  border-radius: 8px;
  background-color: var(--colors-neutral-gray-50, #1F291F);
  color: var(--colors-white, #FFF);
}

.modal-buttons {
  display: flex;
  gap: 16px;
}

.input-modal {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid var(--colors-neutral-gray-600, #DBDCDA);
  border-radius: 8px;
  background-color: var(--colors-neutral-gray-50, #1F291F);
  color: var(--colors-white, #FFF);
  height: 20px;
}
</style>
