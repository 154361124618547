<template>
  <div class="collapsible-header">
    <span>Pull Requests</span>
  </div>
  <div class="collapsible-content">
    <div v-if="showingDiffs">
      <button class="back-button" @click="showingDiffs = false">← Back</button>
      <GitPatch v-for="file in files" 
                :key="file.filename"
                :filename="file.filename"
                :patch="file.patch" />
    </div>
    <ag-grid-vue
      v-else
      class="ag-theme-quartz-dark"
      style="width: 100%;"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :gridOptions="gridOptions"
      :quickFilterText="quickFilterText"
      @grid-ready="onGridReady"
      :domLayout="'autoHeight'"
    ></ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue3';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { marked } from 'marked';
import GitPatch from './GitPatch.vue';

marked.setOptions({
  gfm: true,
  breaks: true,
  sanitize: true,
});

export default {
  name: 'PullRequest',
  components: {
    AgGridVue,
    GitPatch
  },
  props: {
    artifactDisplay: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      quickFilterText: '',
      expandedRows: new Set(),
      showingDiffs: false,
      files: [],
      columnDefs: [
        {
          field: 'title',
          headerName: 'Info',
          flex: 2,
          cellRenderer: params => {
            return `
              <div style="display: flex; flex-direction: column; align-items: start; gap: 0px;">
                <p style="color: inherit; font-size: 16px; font-weight: bold;">${params.value}</p>
                <p style="color: #888888; font-size: 14px;">${params.data.repo} <span style="color: #888888;">#${params.data.number}</span></p>
                <div style="display: flex; gap: 8px; margin-top: 8px;">
                  <button class="show-diffs-button" onclick="document.dispatchEvent(new CustomEvent('showDiffs', {detail: ${params.data.number}}))">Show Diffs</button>
                  <button class="show-diffs-button" onclick="window.open('${params.data.url}', '_blank')">View in GitHub</button>
                </div>
              </div>
            `;
          },
          width: 100,
        },
        {
          field: 'description',
          headerName: 'Summary',
          flex: 3,
          cellRenderer: params => {
            const lines = params.value.split('\n');
            const isExpanded = this.expandedRows.has(params.data.number);
            const displayLines = isExpanded ? lines : lines.slice(0, 6);
            const hasMoreLines = lines.length > 6;
            
            const description = marked(displayLines.join('\n'));
            const toggleButton = hasMoreLines ? 
              `<button class="toggle-button" onclick="document.dispatchEvent(new CustomEvent('toggleDescription', {detail: ${params.data.number}}))">${isExpanded ? 'Show Less' : 'Show More'}</button>` : '';
            
            return `<div>${description}${toggleButton}</div>`;
          }
        }
      ],
      gridOptions: {
        rowHeight: 20,
        defaultColDef: {
          sortable: true,
          filter: true,
          resizable: true,
          wrapText: true,
          autoHeight: true,
        },
        enableCellTextSelection: true,
        animateRows: true,
      }
    }
  },
  computed: {
    rowData() {
      return [...this.artifactDisplay.pull_requests]
        .sort((a, b) => a.repo.localeCompare(b.repo))
        .map(json => ({
          repo: json.repo,
          number: json.number,
          title: json.title,
          description: json.llm_summary || json.description,
          url: json.url
        }));
    }
  },
  methods: {
    onGridReady(params) {
      params.api.sizeColumnsToFit();
      document.addEventListener('toggleDescription', (event) => {
        const number = event.detail;
        if (this.expandedRows.has(number)) {
          this.expandedRows.delete(number);
        } else {
          this.expandedRows.add(number);
        }
        params.api.refreshCells({force: true});
      });

      document.addEventListener('showDiffs', (event) => {
        const number = event.detail;
        const pr = this.artifactDisplay.pull_requests.find(json => json.number === number);
        if (pr && pr.files) {
          this.files = pr.files;
          this.showingDiffs = true;
        }
      });
    }
  }
}
</script>

<style scoped>
.collapsible-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 4px 6px;
  font-size: 16px;
  font-weight: bold;
}

.collapsible-content {
  padding: 10px 6px;
}

.quick-filter-input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--colors-neutral-gray-600, #DBDCDA);
  border-radius: 4px;
  background-color: var(--colors-neutral-gray-800, #0C160B);
  color: var(--colors-white, #FFF);
  font-size: 14px;
}

.quick-filter-input::placeholder {
  color: var(--colors-neutral-gray-600, #DBDCDA);
}

.back-button {
  background: none;
  color: var(--colors-white, #FFF);
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.back-button:hover {
  background-color: var(--colors-neutral-gray-700, #2A2F28);
}

::v-deep .show-diffs-button {
  background: none;
  border: none;
  color: var(--colors-neutral-gray-600, #DBDCDA);
  cursor: pointer;
  font-size: 14px;
  border-radius: 16px;
  display: block;
  text-decoration: underline;
}

::v-deep .show-diffs-button:hover {
  color: var(--colors-white, #FFF);
}

::v-deep .ag-theme-quartz-dark {
  --ag-background-color: var(--colors-neutral-gray-800, #0C160B);
  --ag-foreground-color: var(--colors-white, #FFF);
  --ag-header-background-color: var(--colors-neutral-gray-700, #2A2F28);
}

::v-deep .ag-theme-quartz-dark .ag-header-cell-label .ag-header-cell-text {
  color: var(--colors-white, #FFF);
  font-size: 16px;
}

::v-deep .ag-theme-quartz-dark .ag-cell {
  color: var(--colors-white, #FFF);
  font-size: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-right: 0.5px solid var(--colors-neutral-gray-700, #2A2F28);
}

::v-deep .ag-theme-quartz-dark .ag-cell:last-child {
  border-right: none;
}

::v-deep li {
  list-style-type: disc;
  margin-left: 20px;
}

::v-deep .toggle-button {
  background: none;
  border: none;
  color: var(--colors-neutral-gray-600, #DBDCDA);
  cursor: pointer;
  font-size: 14px;
  border-radius: 16px;
  display: block;
  margin: 12px auto 0px auto;
  text-decoration: underline;
}

::v-deep .toggle-button:hover {
  color: var(--colors-white, #FFF);
}

</style>
