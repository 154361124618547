<template>
  <div :class="['investigation-summary', { active: isActive }]">
    <MiniButton 
      type="ghost"
      class="menu-button"
      icon="search" 
      @click="toggleCollapse" 
    />
    <div 
        class="top-nav-bar"
        v-if="isActive"
    >
        <img :src="require('@/assets/full_logo.svg')" alt="Traversal Logo" class="logo" />
    </div>
    <div v-if="isActive" class="loading-spinner-container">
        <LoadSpinner :isLoading="!fullSummary"/>
    </div>
    <div v-if="isActive && fullSummary" class="summary-content">
      <!-- Display the first user question -->
      <div class="first-question">
        <p 
            class="summary-title-header"
            v-if="firstQuestion"
        >Investigation Title</p>
        <p class="summary-title-content">{{ firstQuestion }}</p>
      </div>

        <!-- Best Answer -->
        <div class="best-answer-section">
            <p class="summary-title-header">Best Answer</p>
            <p class="timeline-item-summary">{{ fullSummary.best_answer }}</p>
        </div>

        <!-- Impact -->
        <div class="impact-section">
            <p class="summary-title-header">Impact</p>
            <p class="timeline-item-summary">{{ fullSummary.impact }}</p>
        </div>

        <!-- Explored Areas -->
        <div class="explored-areas-section">
            <p class="summary-title-header">Explored Areas</p>
            <v-chip
                v-for="(area, index) in fullSummary.explored_areas"
                :key="index"
                :color="area.issues_found ? 'red' : 'green'"
                text-color="white"
                class="ma-2"

            >
                {{ area.area }}
            </v-chip>
        </div>


      <!-- Timeline -->
      <div class="timeline-container">
        <p 
            class="summary-title-header"
            v-if="timelineItems.length > 0"
        >
            Timeline
        </p>
        <v-timeline
            class="investigation-timeline" 
            side="end" 
            truncate-line="both" 
            lineThickness="1" 
            lineColor="#353E34" 
            dotColor="#669D65"
            align="start"
        >
          <v-timeline-item 
            v-for="(item, index) in timelineItems" 
            :key="index"
            class="timeline-item"
            density="compact"
            size="8"
          >
            <div class="timeline-item-content">
              <p class="timeline-item-header">{{ item.title }}</p>
              <p class="timeline-item-summary">{{ item.evidenceSummary }}</p>
              <!-- Display the artifact as it would appear as a PreviewCard -->
              <PreviewCard v-if="item.artifact" :artifact="item.artifact" class="timeline-item-preview" />
            </div>
          </v-timeline-item>
        </v-timeline>
      </div>
    </div>
  </div>
</template>

<script>
import MiniButton from './Buttons/MiniButton.vue';
import { VTimeline, VTimelineItem } from 'vuetify/lib/components/VTimeline';
import { VChip } from 'vuetify/lib/components/VChip';
import PreviewCard from './PreviewCard.vue';
import LoadSpinner from './LoadSpinner.vue';

export default {
  name: 'InvestigationSummary',
  components: {
    MiniButton,
    VTimeline,
    VTimelineItem,
    VChip,
    PreviewCard,
    LoadSpinner,
  },
  props: {
    chatMessages: {
      type: Array,
      required: true,
    },
    auth0: {
        type: Object,
        required: true,
    },
    userId: {
        type: String,
        required: true,
    },
    userEmail: {
        type: String,
        required: true,
    },
    selectedConfig: {
        type: String,
        required: false,
    },
    url: {
        type: String,
        required: true,
    },
  },
  data() {
    return {
      isActive: false, // default to expanded
      fullSummary: null,
      timelineItems: [],
    };
  },
  computed: {
    firstQuestion() {
      // Find the first user message
      const firstUserMessage = this.chatMessages.find(msg => msg.type === 'user');
      return firstUserMessage ? firstUserMessage.text : '';
    },
  },
  methods: {
    toggleCollapse() {
      this.isActive = !this.isActive;
    },
    async getSummary(sessionId) {
        try {
        const { getAccessTokenSilently } = this.auth0;
        const token = await getAccessTokenSilently();

        const response = await fetch(`${this.url}/get_full_summary?session_id=${sessionId}&user_id=${this.userId}&user_email=${this.userEmail}&config_name=${this.selectedConfig}`, {
            headers: {
            'Authorization': `Bearer ${token}`,
            },
        });

        if (response.ok) {
            const data = await response.json();

            //For non-summarized sessions, update the summary
            if (data.summary === null || data.summary === "") {
                this.updateSummary(sessionId);
                return;
            }
            //Otherwise, set the full summary
            this.fullSummary = data.summary;
            console.log("Full summary received:", this.fullSummary);
            this.computeTimelineItems(); // Compute timelineItems after fullSummary is set
            console.log("Timeline computed");
        } else {
            console.error("Failed to fetch full summary:", response.statusText);
        }
        } catch (error) {
        console.error("Error fetching full summary:", error);
        }
    },
    async updateSummary(sessionId) {
        try {
        const { getAccessTokenSilently } = this.auth0;
        const token = await getAccessTokenSilently();

        const response = await fetch(`${this.url}/update_full_summary?session_id=${sessionId}&user_id=${this.userId}&user_email=${this.userEmail}&config_name=${this.selectedConfig}`, {
            headers: {
            'Authorization': `Bearer ${token}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            this.fullSummary = data.summary;
            console.log("Full summary received:", this.fullSummary);
            this.computeTimelineItems(); // Compute timelineItems after fullSummary is set
            console.log("Timeline computed");
        } else {
            console.error("Failed to fetch full summary:", response.statusText);
        }
        } catch (error) {
        console.error("Error fetching full summary:", error);
        }
    },
    computeTimelineItems() {
        if (!this.fullSummary || !this.fullSummary.evidence) {
            this.timelineItems = [];
            return;
        }

        this.timelineItems = this.fullSummary.evidence.map((evidence) => {
            let artifact = null;
            for (let message of this.chatMessages) {
                if (message.artifacts) {
                    artifact = message.artifacts.find(art => art.tool_call_id === evidence.tool_id);
                    if (artifact) break;
                }
            }

            return {
                title: artifact ? artifact.start_time || 'No Time' : 'No Time',
                evidenceSummary: evidence.summary,
                artifact: artifact,
                icon: 'mdi-file-document',
            };
        }).sort((a, b) => a.title.localeCompare(b.title));
    },
    resetSummary() {
        this.fullSummary = null;
        this.timelineItems = [];
    },
  },
};
</script>

<style scoped>
.investigation-summary {
  display: inline-flex;
  flex-direction: column;
  border-right: 1px solid var(--colors-neutral-gray-50, #1F291F);
  transition: width 0.3s ease;
  width: 64px;
  flex-shrink: 0;
  height: 100vh;
  box-sizing: border-box;
  padding: 12px;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
}

.top-nav-bar {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: var(--spacing-3510-px, 10px);
    border-bottom: 1px solid var(--colors-neutral-gray-100, #353E34);
    background: var(--colors-black, #0C160B); 
    margin-top: 12px;  
}

.logo {
    width: 215px;
    height: var(--spacing-520-px, 80px);
    flex-shrink: 0;
    align-self: center;
}


.investigation-summary.active {
  min-width: 310px;
}
.menu-button {
  margin-top: 6px;
}
.summary-content {
  margin-top: 12px;
  overflow-y: auto;
  flex: 1;
}
.first-question {
  margin-bottom: 24px;
}
.timeline-container {
  overflow-y: auto;
  padding-top: 0px;
  padding-bottom: 0px;
}

.summary-title-header {
    color: var(--colors-neutral-gray-400, #929691);

    /* text-base/medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    margin-bottom: 4px;
}

.summary-title-content {
    color: var(--colors-white, #FFF);

    /* text-md/semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}

.timeline-item-header {
    color: var(--colors-neutral-gray-400, #929691);

    /* text-xs/bold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
}

.timeline-item-summary {
    color: var(--colors-neutral-gray-700, #E7E8E7);

    /* text-xs/reg */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

.timeline-item-preview {
  margin: 8px 0;
}

/* Impact Section Styling */
.impact-section {
  margin-top: 12px;
}

.impact-section .summary-title-header {
  color: var(--colors-neutral-gray-300, #B0B3AD);
  font-size: 14px;
  font-weight: 500;
}

.impact-section .summary-title-content {
  color: var(--colors-neutral-gray-700, #E7E8E7);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

/* Explored Areas Styling */
.explored-areas-section {
  margin-top: 16px;
}

.explored-areas-section .summary-title-header {
  color: var(--colors-neutral-gray-300, #B0B3AD);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.explored-areas-section .v-chip {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px;
  border-radius: 16px;
  color: #ffffff;
}

.explored-areas-section .v-chip.green {
  background-color: #4CAF50; /* Customize as needed */
}

.explored-areas-section .v-chip.red {
  background-color: #F44336; /* Customize as needed */
}

/* ensure loading spinner is cenered */
.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure full width of the container */
  margin-top: 20px;
}


/* Scrollbar styling */
.summary-content::-webkit-scrollbar {
  /* width: 6px; */
  width: 0px;


}

.summary-content::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 6px;

  width: 0px;

}

.summary-content::-webkit-scrollbar-track {
  background-color: #161F15;
  border-radius: 6px;
}


</style>
