import { createApp } from 'vue'
import App from './App.vue'
import './styles.css';
import { createAuth0 } from '@auth0/auth0-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

// createApp(App).mount('#app')
const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);

app.use(
    createAuth0({
      domain: "dev-ppocc0m78uclwopp.us.auth0.com",
      clientId: "dn0MZXNPvX59SkMi3KK6vnikyqRewzJP",
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: "https://api.traversal.com/",
      },
      cacheLocation: 'localstorage', // Persist session in local storage
    })
    
  );

// Import Vuetify
import { createVuetify } from 'vuetify'
import 'vuetify/styles'  // Ensure you import Vuetify styles
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Create Vuetify instance
const vuetify = createVuetify({
  components,
  directives,
})

// Use Vuetify
app.use(vuetify)


  
  app.mount('#app');
  