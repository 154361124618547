<template>
  <pre class="code-block" v-html="formattedCode"></pre> <!-- Use <pre> to preserve formatting -->
</template>

<script>
import { createHighlighter } from 'shiki';

export default {
  name: 'CodeBlock',
  props: {
    code: {
      type: String,
      required: true,
    },
    language: {
      type: String,
      default: 'shellsession', // Default language for terminal output
    },
    theme: {
      type: String,
      default: 'nord', // Default theme
    },
  },
  data() {
    return {
      highlightedCode: '', // Will store the highlighted HTML output from Shiki
    };
  },
  computed: {
    // Replace \n with <br> to interpret newlines properly
    formattedCode() {
      //return this.highlightedCode.replace(/\n/g, '<br>');
      return this.highlightedCode.replace(/\\n/g, '\n').replace("\"", "").replace("\"", ""); 

    },
  },
  async mounted() {
    try {
      const highlighter = await createHighlighter({
        themes: ['nord', import('shiki/themes/everforest-dark.mjs'), import('shiki/themes/everforest-light.mjs')],
        langs: ['plaintext', 'javascript', 'html', 'css', 'shellsession'],
      });

      // Generate syntax-highlighted HTML from the code
      this.highlightedCode = highlighter.codeToHtml(this.code, {
        lang: this.language,
        theme: this.theme,
      });
    } catch (error) {
      console.error('Error generating highlighted code:', error);
    }
  },
};
</script>

<style scoped>
.code-block {
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  padding: 10px;
  white-space: pre-wrap; /* This preserves newlines and spaces */
  background-color: #2D353B;
  overflow: hidden;
}
</style>
