<!-- Counter.vue -->
<template>
  <div class="counter" @click="handleClick" @mouseover="highlight" @mouseleave="removeHighlight">
    {{ count }}
  </div>
</template>

<script>
export default {
  name: 'CitationCounter',
  props: {
    count: {
      type: Number,
      required: true,
    },
    previewCardId: {
      type: String,
      required: true,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      this.$emit('open-preview-card', this.previewCardId);
    },
    highlight() {
      this.$emit('highlight-preview-card', this.previewCardId);
    },
    removeHighlight() {
      this.$emit('remove-highlight-preview-card', this.previewCardId);
    },
  },
};
</script>

<style scoped>
.counter {
  display: inline-flex;
  width: 16px;
  height: 16px;
  padding: var(--spacing-052-px, 2px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-3510-px, 10px);
  flex-shrink: 0;
  border-radius: 99px;
  background: var(--colors-green-green-600, #669D65);
  color: var(--colors-green-green-50, #162C15);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  cursor: pointer;
}

.counter:hover {
  background-color: var(--colors-green-green-50, #162C15);
  color: var(--colors-green-green-600, #669D65);
}
</style>
