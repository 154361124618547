<template>
  <div>
    <MiniButton @click="showModal = true" type="secondary" icon="chart-bar-decreasing"></MiniButton>

    <!-- <button class="action-button" @click="showModal = true">View Summary Stats</button> -->

    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <button class="close-button" @click="showModal = false">×</button>
        
        <div class="summary-stats-container">
          <div v-for="stat in truncated_logs_summary_stats" :key="stat.field_name" class="summary-card">
            <h3>{{ stat.field_name }}</h3>
            <p class="frequency-tip">
              (appears {{ stat.field_frequency }} times in {{ stat.sample_size }} random logs)
            </p>
            <div v-for="(count, value) in stat.value_counts" :key="value" class="value-bar">
              <div class="value-bar-text-wrapper">
                <span class="value-bar-text">{{ value }}</span>
                <span class="percentage">{{ getPercentage(count, stat.field_frequency) }}%</span>
              </div>
              <div class="bar-container-wrapper">
                <div class="bar-container">
                  <div class="bar" :style="{ width: getPercentage(count, stat.field_frequency) + '%', flexGrow: 0 }">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniButton from './Buttons/MiniButton.vue';

export default {
  name: 'LogsSummaryStats',
  props: {
    logs_summary_stats: {
      type: Array,
      required: true,
    },
  },
  components: {
    MiniButton,
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    getPercentage(count, total) {
      return ((count / total) * 100).toFixed(0);
    },
  },
  computed: {
    truncated_logs_summary_stats() {
      return this.logs_summary_stats.map(summary => {
        // If there are 4 or fewer values, return as is
        if (Object.keys(summary.value_counts).length <= 4) {
          return summary;
        }

        // Get entries sorted by count in descending order
        const entries = Object.entries(summary.value_counts)
          .sort(([, a], [, b]) => b - a);

        // Take first 4 entries
        const topEntries = entries.slice(0, 4);

        // Sum up remaining counts for "Other"
        const otherCount = entries.slice(4)
          .reduce((sum, [, count]) => sum + count, 0);

        // Create new value_counts object with top 4 + Other
        const newValueCounts = Object.fromEntries([
          ...topEntries,
          ['Other', otherCount]
        ]);

        return {
          ...summary,
          value_counts: newValueCounts
        };
      });
    }
  },
};
</script>

<style scoped>
.value-bar-text-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.action-button {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  background-color: var(--colors-neutral-gray-700, #2A2F28);
  color: var(--colors-neutral-gray-600, #DBDCDA);
  border: 1px solid var(--colors-neutral-gray-600, #3D423B);
  transition: all 0.2s ease;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  background-color: var(--colors-neutral-gray-700, #2A2F28);
  border-radius: 8px;
  padding: 24px;
  padding-top: 28px;
}

.close-button {
  position: absolute;
  top: -4px;
  right: 7px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
}

.summary-button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--colors-neutral-gray-700, #2A2F28);
  color: #fff;
  border: 1px solid var(--colors-neutral-gray-600, #3D423B);
  cursor: pointer;
  transition: background-color 0.2s;
}

.summary-button:hover {
  background-color: var(--colors-neutral-gray-600, #3D423B);
}

h3 {
  font-size: 18px;
  font-weight: bold;
}

.frequency-tip {
  font-size: 12px;
  color: var(--colors-neutral-gray-600, #DBDCDA);
  padding-bottom: 10px;
  border-bottom: 1px solid var(--colors-neutral-gray-600, #DBDCDA);
}

.summary-stats-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  background-color: var(--colors-neutral-gray-700, #2A2F28);
  border-radius: 6px;
}

.summary-card {
  padding: 16px;
  padding-right: 10px;
  padding-top: 12px;
  border-radius: 4px;
  width: 300px;
  background-color: var(--colors-neutral-gray-800, #0C160B);
  color: #fff;
}

.value-bar {
  margin-top: 6px;
}

.value-bar-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 84%;
  font-size: 14px;
}

.bar-container {
  display: flex;
  align-items: center;
  background-color: #ccc;
  flex-grow: 1;
  height: 10px;
  border-radius: 4px;
  margin-top: 2px;
}

.bar {
  height: 10px;
  background-color: rgba(238, 199, 41, 1);
  height: 100%;
  /* border-radius: 4px 0 0 4px; */
  border-radius: 4px;
}

.percentage {
  text-align: right;
  font-size: 14px;
  font-weight: bold;
}

.bar-container-wrapper {
  display: flex;
  gap: 8px;
}
</style>
