<template>
  <button
    :class="[
      'base-button',
      `button-${type}`,
      { 'hover-button': isHovered },
      { 'focused-button': isFocused },
      { 'disabled-button': disabled }
    ]"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @focus="handleFocus"
    @blur="handleBlur"
    :disabled="disabled"
  >
    <span v-if="iconPosition === 'left'" class="button-icon">
      <svg
        v-if="icon === 'arrow'"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.52862 2.86193C7.78896 2.60158 8.21108 2.60158 8.47142 2.86193L13.1381 7.5286C13.3984 7.78895 13.3984 8.21106 13.1381 8.47141L8.47142 13.1381C8.21108 13.3984 7.78896 13.3984 7.52862 13.1381C7.26827 12.8777 7.26827 12.4556 7.52862 12.1953L11.0572 8.66667H3.33335C2.96516 8.66667 2.66669 8.36819 2.66669 8C2.66669 7.63181 2.96516 7.33334 3.33335 7.33334H11.0572L7.52862 3.80474C7.26827 3.54439 7.26827 3.12228 7.52862 2.86193Z"
          :fill="arrowColor"
        />
      </svg>
      <svg
        v-if="icon === 'menu'"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6ZM3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12ZM3 18C3 17.4477 3.44772 17 4 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H4C3.44772 19 3 18.5523 3 18Z"
          :fill="arrowColor"
        />
      </svg>
      <svg
        v-if="icon === 'plus'"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 4C12.5523 4 13 4.44772 13 5V11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H13V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H11V5C11 4.44772 11.4477 4 12 4Z"
          :fill="arrowColor"
        />
      </svg>
      <svg
        v-if="icon === 'carrot'"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071L12 10.4142L6.70711 15.7071C6.31658 16.0976 5.68342 16.0976 5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929L11.2929 8.29289Z"
          :fill="arrowColor"
        />
      </svg>
      <svg
        v-if="icon === 'exitarrow'"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 8C6.44772 8 6 7.55228 6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V9.41421L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071C5.90237 17.3166 5.90237 16.6834 6.29289 16.2929L14.5858 8H7Z"
          :fill="arrowColor"
        />
      </svg>
    </span>
    <slot></slot>
    <span v-if="iconPosition === 'right'" class="button-icon">
      <svg
        v-if="icon === 'arrow'"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.52862 2.86193C7.78896 2.60158 8.21108 2.60158 8.47142 2.86193L13.1381 7.5286C13.3984 7.78895 13.3984 8.21106 13.1381 8.47141L8.47142 13.1381C8.21108 13.3984 7.78896 13.3984 7.52862 13.1381C7.26827 12.8777 7.26827 12.4556 7.52862 12.1953L11.0572 8.66667H3.33335C2.96516 8.66667 2.66669 8.36819 2.66669 8C2.66669 7.63181 2.96516 7.33334 3.33335 7.33334H11.0572L7.52862 3.80474C7.26827 3.54439 7.26827 3.12228 7.52862 2.86193Z"
          :fill="arrowColor"
        />
      </svg>
      <svg
        v-if="icon === 'menu'"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6ZM3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12ZM3 18C3 17.4477 3.44772 17 4 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H4C3.44772 19 3 18.5523 3 18Z"
          :fill="arrowColor"
        />
      </svg>
      <svg
        v-if="icon === 'plus'"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 4C12.5523 4 13 4.44772 13 5V11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H13V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H11V5C11 4.44772 11.4477 4 12 4Z"
          :fill="arrowColor"
        />
      </svg>
      <svg
        v-if="icon === 'carrot'"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071L12 10.4142L6.70711 15.7071C6.31658 16.0976 5.68342 16.0976 5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929L11.2929 8.29289Z"
          :fill="arrowColor"
        />
      </svg>
      <svg
        v-if="icon === 'exitarrow'"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 8C6.44772 8 6 7.55228 6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V9.41421L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071C5.90237 17.3166 5.90237 16.6834 6.29289 16.2929L14.5858 8H7Z"
          :fill="arrowColor"
        />
      </svg>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'primary', // 'primary', 'secondary', 'standard', 'ghost'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'arrow', // 'arrow', 'menu', 'plus'
    },
    iconPosition: {
      type: String,
      default: 'right', // 'left' or 'right'
    },
  },
  data() {
    return {
      isHovered: false,
      isFocused: false
    };
  },
  computed: {
    arrowColor() {
      switch (this.type) {
        case 'secondary':
          return '#669D65'; // Example color for secondary
        case 'standard':
          return '#FFF'; // Example color for standard
        case 'ghost':
          return '#C2C5C2'; // Example color for ghost
        default:
          return '#FFFFFF'; // Default color for primary
      }
    }
  },
  methods: {
    handleMouseEnter() {
      if (!this.disabled) {
        this.isHovered = true;
      }
    },
    handleMouseLeave() {
      this.isHovered = false;
    },
    handleFocus() {
      if (!this.disabled) {
        this.isFocused = true;
      }
    },
    handleBlur() {
      this.isFocused = false;
    }
  }
};
</script>

<style scoped>
.base-button {
  display: inline-flex;
  padding: var(--spacing-3510-px, 10px) 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: var(--spacing-border-radius-rounded-lg, 8px);
  border: 0px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: var(--colors-green-green-600, #669D65);

  /* text-base/semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.button-primary {
  background: var(--colors-green-green-500, #40853E);
  color: white;
}

.button-secondary {
  background: var(--colors-green-green-50-alpha, rgba(64, 133, 62, 0.20));
  color: var(--colors-green-green-600, #669D65);
}

.button-standard {
  background: var(--colors-black, #0C160B);  
  border: 1px solid var(--colors-neutral-gray-200, #495048);
  font-weight: 500;
  color: var(--colors-white, #FFF);
}

.button-ghost {
  background: transparent;
  font-weight: 500;
  color: var(--colors-neutral-gray-500, #C2C5C2);
}

.hover-button.button-primary {
  background: var(--colors-green-green-600, #669D65);
}

.hover-button.button-secondary {
  background: var(--colors-green-green-100-alpha, rgba(64, 133, 62, 0.35));
}

.hover-button.button-standard {
  background: var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
  border: 1px solid var(--colors-neutral-gray-200, #495048);

}

.hover-button.button-ghost {
  border-radius: var(--spacing-border-radius-rounded-lg, 8px);
  background: var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
}

.focused-button.button-primary {
  border-radius: var(--spacing-border-radius-rounded-lg, 8px);
  outline: 4px solid var(--colors-green-green-100-alpha, rgba(12, 22, 11, 0.35));
  background: var(--colors-green-green-500, #40853E);
}

.focused-button.button-secondary {
  border-radius: var(--spacing-border-radius-rounded-lg, 8px);
  outline: 4px solid var(--colors-green-green-100-alpha, rgba(64, 133, 62, 0.35));
  background: var(--colors-green-green-50-alpha, rgba(64, 133, 62, 0.20));
}

.focused-button.button-standard {
  border-radius: 8px;
  outline: 1px solid var(--colors-neutral-gray-200, #495048);
  background: var(--colors-black, #0C160B);
  box-shadow: 0px 0px 1px 4px rgba(255, 255, 255, 0.10);
}

.focused-button.button-ghost {
  border-radius: 8px;
  outline: 4px solid var(--colors-neutral-gray-50-alpha, rgba(255, 255, 255, 0.08));
}

.disabled-button.button-primary {
  cursor: not-allowed;
  border-radius: var(--spacing-border-radius-rounded-lg, 8px);
  opacity: 0.4;
  background: var(--colors-neutral-gray-300, #6D736D);
  z-index: 1;
}

.disabled-button.button-secondary {
  border-radius: var(--spacing-border-radius-rounded-lg, 8px);
  opacity: 0.4;
  background: var(--colors-neutral-gray-50-alpha, rgba(255, 255, 255, 0.08));
  cursor: not-allowed;
}

.disabled-button.button-standard {
  border-radius: var(--spacing-border-radius-rounded-lg, 8px);
  border: 1px solid var(--colors-neutral-gray-200, #495048);
  opacity: 0.4;
  background: var(--colors-black, #0C160B);
  cursor: not-allowed;
}

.disabled-button.button-ghost {
  border-radius: var(--spacing-border-radius-rounded-lg, 8px);
  opacity: 0.4;
  background: var(--colors-black, #0C160B);
  cursor: not-allowed;
}

.button-icon {
  display: inline-flex;
  align-items: center;
}

.button-icon svg {
  transition: transform 0.1s ease;
  transform: rotate(0deg);
}

.collapsed-button .button-icon svg {
  transform: rotate(180deg); /* Rotation when collapsed */
}

</style>
