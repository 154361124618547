<!-- EvidenceCollapsible.vue -->
<template>
  <div class="evidence-collapsible">

    <!-- Collapsible Header -->
    <div :class="['collapsible-header', { 'collapsed': isCollapsed }]" @click="toggleCollapse">
        <CitationCounter
        v-if="tool_id && count >= 1"
        :count="count"
        :previewCardId="tool_id"
        class="citation-counter"
        @open-preview-card="openPreviewModalForToolId"
        @highlight-preview-card="highlightCard"
        @remove-highlight-preview-card="removeHighlightCard"
        />
        <h3 class="evidence-summary">{{ evidenceSummary }}</h3>
    </div>

    <!-- Expanded Content -->
    <transition name="collapse">
      <div v-show="!isCollapsed" class="collapsible-content">
        <div class="evidence-details" v-html="formattedEvidenceDetails"></div>
        <PreviewCard 
            v-if="artifact" 
            class="preview-card"
            :artifact="artifact" 
            @open-preview-modal="openPreviewModal"
            count="0"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import PreviewCard from './PreviewCard.vue'; // This will be a larger version of PreviewCard
import CitationCounter from './CitationCounter.vue';

export default {
  name: 'EvidenceCollapsible',
  components: {
    PreviewCard,
    CitationCounter,
  },
  props: {
    evidenceSummary: {
      type: String,
      required: true,
    },
    evidenceDetails: {
      type: String,
      required: true,
    },
    artifact: {
      type: Object,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    tool_id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isCollapsed: false,
    };
  },
  computed: {
    formattedEvidenceDetails() {
      if (!this.evidenceDetails) return '';
      return this.evidenceDetails.replace(/\n/g, '<br>'); // Replace newlines with HTML line breaks
    },
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    openPreviewModal() {
      // Emit an event to open a modal with the preview card
      this.$emit('open-preview-modal', this.artifact);
    },
    openPreviewModalForToolId(toolId) {
      // Emit an event to open a modal with the preview card
      this.$emit('open-preview-card', toolId);
    },
    highlightCard(toolId) {
      // Emit an event to highlight the preview card
      this.$emit('highlight-preview-card', toolId);
    },
    removeHighlightCard(toolId) {
      // Emit an event to remove the highlight from the preview card
      this.$emit('remove-highlight-preview-card', toolId);
    },
  },
};
</script>

<style scoped>
.evidence-collapsible {
  position: relative;
  margin: 0 auto;
  width: 550px;
}

.preview-card {
  width: 520px;
  margin: 0 auto;
}

/* Line styling */

.collapsible-header {
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 12px;
  gap: 8px;
  border-radius: 8px 8px 0px 0px;
  background: var(--colors-neutral-gray-25, #161F15);
  border: 1px solid var(--colors-neutral-gray-100, #353E34);
  cursor: pointer;
}

.collapsible-header.collapsed {
  border-radius: 8px;
}

.collapsible-header:hover {
  background: #2A2E2A;
}

.evidence-summary {
    font-size: 14px;
    color: var(--colors-neutral-gray-800, #F3F3F3);

    /* text-base/medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.collapsible-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px 20px 16px;
  gap: 12px;
  border-radius: 0px 0px 8px 8px;
  background: var(--colors-black, #0C160B);
  border-right: 1px solid var(--colors-neutral-gray-100, #353E34);
  border-bottom: 1px solid var(--colors-neutral-gray-100, #353E34);
  border-left: 1px solid var(--colors-neutral-gray-100, #353E34);
}

.evidence-details {
  font-size: 12px;
  color: var(--colors-neutral-gray-500, #C2C5C2);
}

.citation-counter {
  display: inline-flex; /* Ensure counter appears inline with the text */
  margin-left: 4px;
  vertical-align: middle; /* Align the counter with the text */
}


/* Animation for collapse */
/*.collapse-enter-active, .collapse-leave-active {
  transition: max-height 0.3s ease;
}*/

.collapse-enter, .collapse-leave-to /* .collapse-leave-active in <2.1.8 */ {
  max-height: 0;
  overflow: hidden;
}
</style>
